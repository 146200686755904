import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {AuthContext} from "../Auth/Auth";

class Steps extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.session = null;

        this.state = {
            country: "0",
            email: "",
            firstName: "",
            lastName: "",
            comment: "",
            success: "",
            error: ""
        }
    }

    componentDidMount() {
        this.context.getSession().then((session) => {
            this.session = session;

            fetch(`https://5f8egpl786.execute-api.eu-central-1.amazonaws.com/DEV/client/${this.session.user.username}/info`,
                {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.session.session.idToken.jwtToken,
                        'Origin': ''
                    }
                })
                .then(res => res.json())
                .then(res => {
                    if (res["Item"]["onboarding"]) {
                        this.setState({
                            country: res["Item"]["onboarding"]["country"],
                            email: res["Item"]["onboarding"]["email"],
                            companyName:res["Item"]["onboarding"]["companyName"],
                            firstName: res["Item"]["onboarding"]["firstName"],
                            lastName: res["Item"]["onboarding"]["lastName"],
                            comment: res["Item"]["onboarding"]["comment"],
                            success: "Your passed onboarding process."
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // this.setState({redirect: true, loading: false});
                });
        });
    }

    onUnlock() {
        this.setState({locked: false});
    }

    onSubmit(event) {
        event.preventDefault();

        this.context.getSession().then((result) => {
            fetch(`https://5f8egpl786.execute-api.eu-central-1.amazonaws.com/DEV/client/${result.user.username}/onboarding`,
                {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + result.session.idToken.jwtToken,
                        'Origin': ''
                    },
                    body: JSON.stringify({
                        country: this.state.country,
                        email: this.state.email,
                        firstName: this.state.typeOfBusiness === Steps.INDIVIDUAL ? this.state.firstName: "",
                        lastName: this.state.typeOfBusiness === Steps.INDIVIDUAL ? this.state.lastName: "",
                        comment: this.state.comment
                    })
                })
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        locked: true,
                        success: "Your passed onboarding process."
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <Container>
                    <Row className={"justify-content-md-center"}>
                        <Col>
                            {
                                this.state.error &&
                                <Alert variant={"danger"}>
                                    {
                                        this.state.error.map(err => {
                                            return <div>{err}</div>
                                        })
                                    }
                                </Alert>
                            }
                            {
                                this.state.success &&
                                <Alert variant={"success"}>
                                    {this.state.success}
                                </Alert>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Onboarding process</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"d-flex justify-content-center"}>

                            <Container>

                                <Row className="mb-2">

                                    <Form.Group as={Col} className="mb-3" sm={12}
                                                md={6}>
                                        <Form.Label>
                                            Country:
                                        </Form.Label>
                                        <Form.Control as="select"
                                                      value={this.state.country}
                                                      onChange={(event) => {
                                                          this.setState({country: event.target.value});
                                                      }}>
                                            <option value={0}>Choose...</option>
                                            <option value={"Austria"}>Austria</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Republicof Cyprus">Republicof Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sweden">Sweden</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3"
                                                sm={12}
                                                md={6}>
                                        <Form.Label>
                                            Email:
                                        </Form.Label>
                                        <Form.Control placeholder="Enter email address"
                                                      type={"email"}
                                                      value={this.state.email}
                                                      onChange={(event) => this.setState({email: event.target.value})}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                        <Form.Label>
                                            First Name:
                                        </Form.Label>
                                        <Form.Control placeholder="Enter first name"
                                                      value={this.state.firstName}
                                                      onChange={(event) => this.setState({firstName: event.target.value})}/>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" sm={12} md={6}>
                                        <Form.Label>
                                            Last Name:
                                        </Form.Label>
                                        <Form.Control placeholder="Enter last name"
                                                      value={this.state.lastName}
                                                      onChange={(event) => this.setState({lastName: event.target.value})}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>
                                            Comment:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            value={this.state.comment}
                                            onChange={(event) => this.setState({comment: event.target.value})}
                                            style={{height: '100px'}}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>

                                    <Col className={"d-flex align-items-center justify-content-end"}>
                                        <Button variant="primary" type="submit"
                                                disabled={this.state.country === "0" || !this.state.email || !this.state.firstName || !this.state.lastName}>
                                            Finish
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </form>
        )
    }
}

export default Steps;