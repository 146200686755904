import React from "react";
import SignedComponent from "./SignedComponent";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

class Header extends SignedComponent {

    render() {
        return (
            <div>
                <Navbar bg="primary" variant="dark">
                    <Container>
                        <Navbar.Brand>Healthics Client {this.props.headerText}</Navbar.Brand>
                        <Nav className="justify-content-end">
                            <Nav.Item className="ml-auto">
                                <Nav.Link href="/sign-out">Sign Out</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar>
                {this.props.children}
            </div>
        );
    }
}

export default Header;