import React, {Component} from "react";
import {AuthContext} from './Auth';
import {Link, Redirect} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class MFAVerificationCode extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            code: "",
            success: "Please check you phone number for verification code.",
            error: null,
            redirect: false
        };
    }

    onVerify(event) {

        this.context.sendMFACode(this.state.code)
            .then((data) => {
                console.log(data);
                this.setState({redirect: true});
            })
            .catch(err => {
                console.error("Failed to verify", err);
                this.setState({error: err.message, success: false});
                setTimeout(() => {
                    this.setState({error: null});
                }, 10000);
            });
    }

    render() {
        return (
            (this.state.redirect) ?
                <Redirect to={{pathname: "/dashboard"}}/>
                :
                <form>
                    <Container className={"vh-100"}>
                        <Row className={"justify-content-md-center"}>
                            <Col>
                                {
                                    this.state.success &&
                                    <Alert variant={"success"}>
                                        {this.state.success}
                                    </Alert>
                                }
                                {
                                    this.state.error &&
                                    <Alert variant={"danger"}>
                                        {this.state.error.replace("USERNAME", "Phone Number")}
                                    </Alert>
                                }
                            </Col>
                        </Row>
                        <Row className={"d-flex justify-content-center"} style={{height:"80%"}}>
                            <Col lg={"5"} md={"7"} sm={"9"} xs={"11"}
                                 className={"d-flex align-items-center justify-content-center"}>

                                <Container>
                                    <Row className="mb-2">

                                        <Form.Group as={Col} className="mb-3" >
                                            <Form.Label>
                                                Verification Code:
                                            </Form.Label>
                                            <Form.Control type="code" placeholder="6 digit code" value={this.state.code}
                                                          onChange={(event) =>
                                                              this.setState({code: event.target.value})}/>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                            <Button variant="primary" type="button" className={"w-75"}
                                                    onClick={this.onVerify.bind(this)}>
                                                Verify
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </form>
        );
    }
}

export default MFAVerificationCode;