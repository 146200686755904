import React, { Component } from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import Section1 from "./components/Sections/section1";
import Section2medical from "./components/Sections/section2medical";
import Section3wellness from "./components/Sections/section3wellness";
import Section4insurance from "./components/Sections/section4insurance";
import Section6video from "./components/Sections/section6video";
import Section7 from "./components/Sections/section7";
import Section9 from "./components/Sections/section9";
import Footer from "./components/Footer/footer";
import NavbarPage from "./components/Navbar/NavbarPage";
import Section8 from "./components/Sections/section8";
import Section10 from "./components/Sections/section10";
import Section11 from "./components/Sections/section11";
import Section12 from "./components/Sections/section12";

import {Account} from "./components/Admin/Account";
import SignIn from "./components/Auth/SignIn";
import VerifyCode from "./components/Auth/VerifyUser";
import ForgotPassword from "./components/Auth/ForgotPassword";
import SignOut from "./components/Auth/SignOut";
import SignUp from "./components/Auth/SignUp";
import Header from "./components/Admin/Header";
import SignedComponent from "./components/Admin/SignedComponent";
import Dashboard from "./components/Admin/Dashboard";

//  Magnific-popup
import "./assets/css/magnific-popup.css";
import "./assets/css/video-react.css";

//  css
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";
import MFAVerificationCode from "./components/Auth/MFAVerificationCode";
import Auth from "./components/Auth/Auth";

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pos: document.documentElement.scrollTop,
			imglight: false,
			navClass: "",
			isStickyNav: true,
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.scrollNavigation, true);
		this.scrollNavigation();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.scrollNavigation, true);
	}

	scrollNavigation = () => {
		var scrollup = document.documentElement.scrollTop;

		if (scrollup > this.state.pos) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else if (window.innerWidth <= 768) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else {
			this.setState({navClass: "", imglight: true});
			this.setState({isStickyNav: true});
		}
	};

	render() {
		return (
			<Auth>
				<Router>
					<Switch>
						<Route exact path="/">
							<React.Fragment>
								<NavbarPage
									navclass={this.state.navClass}
									imglight={this.state.imglight}
									isStickyNav={this.state.isStickyNav}
								/>

								<Section1/>

								<Section2medical/>

								<Section3wellness/>

								<Section4insurance/>

								{/*<Section5/>*/}

								<Section6video/>

								<Section7/>

								<Section8/>

								<Section9/>

								<Section10/>

								<Section11/>

								<Section12/>

								<Footer/>
							</React.Fragment>
						</Route>
						<Route exact path="/dashboard">
							<Header headerText={"Dashboard"}>
								<SignedComponent/>
								<Dashboard/>
							</Header>
						</Route>
						<Route path="/sign-up/:phoneNumber" children={<div><SignUp/></div>} />
						<Route path="/sign-up" children={<div><SignUp/></div>} />
						<Route path="/sign-in">
							<div>
								<SignIn/>
							</div>
						</Route>
						<Route path="/mfa-verification-code">
							<div>
								<MFAVerificationCode />
							</div>
						</Route>
						<Route path="/verify-code">
							<div>
								<VerifyCode/>
							</div>
						</Route>
						<Route path="/forgot">
							<div>
								<ForgotPassword/>
							</div>
						</Route>
						<Route path="/sign-out">
							<div>
								<SignOut/>
							</div>
						</Route>
					</Switch>
				</Router>
			</Auth>
		);
	}
}

export default App;
