import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/access-1.png";
import img2 from "../../assets/images/access-2.png";
import SectionTitle from "../Common/SectionTitle";

class Section11 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>
						<SectionTitle
							title="EQUAL ACCESS MEANS EQUAL COMMUNICATION, FOR EVERYONE"
							description="Communication platform that does not compromise on the vulnerable. You can change your prescriptions’ default communication settings to voice, text, video or other methods (subject to availability from vendors)."
						/>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img2}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section11;
