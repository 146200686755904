import React, {Component} from "react";
import {AuthContext} from './Auth';
import {
    Link,
    Redirect,
    withRouter
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class SignUp extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            redirect: false,
            error: null,
            signedUp: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.phoneNumber) {
            this.setState({phoneNumber: this.props.match.params.phoneNumber});
        }
    }

    validate() {
        let errors = [];
        if (!this.state.phoneNumber) {
            errors.push("phoneNumber can not be empty");
        }
        if (!this.state.password) {
            errors.push("Password can not be empty");
        }
        if (!this.state.confirmPassword) {
            errors.push("Confirm Password can not be empty");
        }
        if (this.state.password !== this.state.confirmPassword) {
            errors.push("Confirm password does not match to Password");
        }
        return errors;
    }

    onSubmit(event) {
        event.preventDefault();

        let _this = this;

        let errors = this.validate();

        if (errors.length > 0) {
            this.setState({
                error: errors
            });
            return;
        }

        this.context.signUp(this.state.phoneNumber, this.state.password)
            .then(data => {
                _this.setState({
                    redirect: true,
                    signedUp: true
                });
            })
            .catch(err => {
                this.setState({
                    error: [err.message]
                });
            });
    }

    render() {
        return (
            (this.state.redirect) ?
                <Redirect to={{pathname: "/verify-code"}}/>
                :
                <form onSubmit={this.onSubmit.bind(this)}>
                    <Container className={"vh-100"}>
                        <Row className={"justify-content-md-center"}>
                            <Col>
                                {
                                    this.state.error &&
                                    <Alert variant={"danger"}>
                                        {
                                            this.state.error.map(err => {
                                                return <div key={err.toString()}>{err.replace("USERNAME", "Phone Number")}</div>
                                            })
                                        }
                                    </Alert>
                                }
                            </Col>
                        </Row>
                        <Row className={"d-flex justify-content-center"} style={{height: "80%"}}>
                            <Col lg={"5"} md={"7"} sm={"9"} xs={"11"}
                                 className={"d-flex align-items-center justify-content-center"}>

                                <Container>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} className="mb-3">
                                            <Form.Label>
                                                Phone Number:
                                            </Form.Label>
                                            <Form.Control placeholder="Phone Number" value={this.state.phoneNumber}
                                                          onChange={(event) => this.setState({phoneNumber: event.target.value})}/>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} className="mb-3">
                                            <Form.Label>
                                                Password:
                                            </Form.Label>
                                            <Form.Control type="password" placeholder="Password"
                                                          value={this.state.password}
                                                          onChange={(event) => this.setState({password: event.target.value})}/>
                                        </Form.Group>

                                        <Form.Group as={Col} className="mb-3">
                                            <Form.Label>
                                                Confirm Password:
                                            </Form.Label>
                                            <Form.Control type="password" placeholder="Confirm Password"
                                                          value={this.state.confirmPassword}
                                                          onChange={(event) => this.setState({confirmPassword: event.target.value})}/>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                            <Button variant="primary" type="submit">
                                                Sign Up
                                            </Button>
                                        </Col>
                                        <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                            <Link to="/sign-in">Sign In</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </form>
        );
    }
}

export default withRouter(SignUp);