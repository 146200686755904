import React, { Component } from "react";
import {
	Container,
	Row,
	Col
} from "reactstrap";
import { Player } from 'video-react';

//Slider
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Client images
import video1 from "../../assets/video/Media1.mp4";
import SectionTitle from "../Common/SectionTitle";

class Section6video extends Component {
	state = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			960: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
	};

	render() {
		return (
			<React.Fragment>
				{/* <!-- TESTIMONIAL START --> */}

				<section className="section position-relative" id="client">
					<Container>
						<SectionTitle
							title="A modern healthcare wallet for digital times"
							description="We operate a secure communication platform that deploys healthcare products and services in real time."
						/>
						<div className="row">

							<div className="embed-responsive">
								<Player
									playsInline
									src={video1}
								/>
							</div>
						</div>
					</Container>
				</section>
				{/* TESTIMONIAL END */}
			</React.Fragment>
		);
	}
}

export default Section6video;
