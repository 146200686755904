import React, { Component } from "react";
import {
	Container,
	Row,
	Col
} from "reactstrap";

//Slider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Client images
import wellnessCaroselMainImg from "../../assets/images/wellness-carosel-1.png";
import wellnessServiceImg1 from "../../assets/images/wellness-service-1.png";
import wellnessServiceImg2 from "../../assets/images/wellness-service-2.png";
import wellnessServiceImg3 from "../../assets/images/wellness-service-3.png";
import wellnessServiceImg4 from "../../assets/images/wellness-service-4.png";
import SectionTitle from "../Common/SectionTitle";

class Section3wellness extends Component {
	state = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			960: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
	};

	render() {
		return (
			<React.Fragment>
				{/* <!-- TESTIMONIAL START --> */}

				<section className="section position-relative" id="client">
					<Container>
						<SectionTitle
							title="Wellness Products &amp; Services"
							description="Find wellness in your pocket. Get a fitness or a nutrition plan today from the marketplace."
						/>
						<div className="row">
							<OwlCarousel
								className="owl-theme client-images text-center"
								items={2}
								loop={true}
								margin={10}
								nav={false}
								dots={true}
								autoplay={true}
								responsive={this.state.responsive}
								autoplayHoverPause={true}
								autoplayTimeout={2500}
							>
								<div className="item">
									<section
										style={{background: `url(${wellnessCaroselMainImg}) center center no-repeat`, backgroundSize: "cover"}}
									>
										<Container style={{height: "600px"}}>

											<Row style={{paddingTop: "300px"}}>
												<Col>
													<h1 className="text-white" style={{fontSize:"40px"}}>
														Wellness
													</h1>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<button
														type="button"
														className="btn rounded-pill btn-white"
														style={{color: "black"}}
													>
														Sign Up For Free
													</button>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<h6 className="text-white px-5" style={{fontSize:"12px"}}>
														The healthcare budget of EU 27 is 1.5 trillion euro. Digital healthcare can reduce it by 50% and make delivery 60% faster
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#EBD6D6`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Services that inspires you to live
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={wellnessServiceImg1} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Get a quick overview of your wellness plan and activities
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#EBD6D6`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Wellness plan tailored to you
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={wellnessServiceImg2} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Gamification ensures that you reach the best performance
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#EBD6D6`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Start your favourite sport today
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={wellnessServiceImg3} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														From cycling to tennis, find what gets you going
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#EBD6D6`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Share your wellness data for rewards
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={wellnessServiceImg4} />
												</Col>
											</Row>

											<Row style={{paddingTop: "60px"}}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Decide how much of your data is shared with vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

							</OwlCarousel>
						</div>
					</Container>
				</section>
				{/* TESTIMONIAL END */}
			</React.Fragment>
		);
	}
}

export default Section3wellness;
