import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Container, Row, Col} from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
	state = {
		footerItems: [
			{
				title: "About Company",
				links: [
					{linkTitle: "About Us", link: "/"},
					{linkTitle: "Cyber-security", link: "#"},
					{linkTitle: "Standards and Protocols", link: "#"},
					{linkTitle: "GDPR Compliance", link: "#"},
					{linkTitle: "Contact Us", link: "#"},
				],
			},
			{
				title: "Personal",
				links: [
					{linkTitle: "Digital Health Wallet", link: "#"},
					{linkTitle: "Wellness Services", link: "#"},
					{linkTitle: "e-Prescription", link: "#"},
					{linkTitle: "e-Fitness & Nutrition", link: "#"},
					{linkTitle: "e-Insurance", link: "#"},
				],
			},
			{
				title: "Business",
				links: [
					{linkTitle: "Healthics Cloud", link: "#"},
					{linkTitle: "Healthcare Professionals", link: "#"},
					{linkTitle: "Wellness Coaches", link: "#"},
					{linkTitle: "Health Data API", link: "#"},
					{linkTitle: "Developers API", link: "#"},
				],
			},
			{
				title: "Government",
				links: [
					{linkTitle: "IT infrastructure", link: "#"},
					{linkTitle: "Stakeholders", link: "#"},
					{linkTitle: "EU Health Insurance Card", link: "#"},
					{linkTitle: "EU Cross-border Health", link: "#"},
					{linkTitle: "EU Health Data Space", link: "#"},
				],
			},
		],
		socialIcons: [
			{icon: "remixicon-facebook-line", iconLink: "#"},
			{icon: "remixicon-twitter-line", iconLink: "#"},
			{icon: "remixicon-instagram-line", iconLink: "#"},
			{icon: "remixicon-skype-line", iconLink: "#"},
		],
	};

	render() {
		return (
			<React.Fragment>
				{/* FOOTER START  */}
				<footer className="pt-5 pb-4 position-relative"  style={{backgroundColor: "#EDECF3"}}>
					<Container className="container">
						<Row>
							<Col lg={12}>
								<div className="row">
									{this.state.footerItems.map((item, key) => (
										<div className="col-md-3" key={key}>
											<div className="mt-3">
												<h5 className="text-dark footer-title font-weight-medium mb-4">
													{item.title}
												</h5>
												<ul className="list-unstyled footer-sub-menu">
													{item.links.map((fLink, key) => (
														<li className="f-15 mt-3 mb-3" key={key}>
															<Link to={fLink.link} className="text-muted">
																{fLink.linkTitle}
															</Link>
														</li>
													))}
												</ul>
											</div>
										</div>
									))}
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg={12} className={"mt-5"}>
								<Link to={"#"} className="text-muted">Terms</Link>
								<Link to={"#"} className="text-muted ml-3">Legal Agreements</Link>
								<Link to={"#"} className="text-muted ml-3">Privacy Policy</Link>
								<Link to={"#"} className="text-muted ml-3">Complaints</Link>
								<Link to={"#"} className="text-muted ml-3">Data Privacy For Candidates</Link>
								<Link to={"#"} className="text-muted ml-3">Responsible Disclosure Program</Link>
							</Col>
						</Row>
						<Row>
							<Col lg={12} className={"mt-5"}>
								<span style={{fontSize: "10px"}} className={"p-0 m-0"}>
								If you would like to find out more about which Revolut entity you receive services from, or if you have any other questions, please reach out to us via the in-app chat in the Revolut app. Revolut Bank UAB is a specialised bank established in the Republic of Lithuania. Registered address: Konstitucijos ave. 21B, Vilnius, 08130, the Republic of Lithuania, number of registration 304580906, FI code 70700. Revolut Bank UAB is licensed by the European Central Bank and regulated by the Bank of Lithuania. Revolut Payments UAB is an electronic money institution established in the Republic of Lithuania. Registered address: Konstitucijos ave. 21B, Vilnius, 08130, the Republic of Lithuania, number of registration 304940980, FI Code 32500. Revolut Payments UAB is authorised and regulated by the Bank of Lithuania. Insurance related-products for Revolut Payments UAB customers are also provided by Revolut Payments UAB, which is authorised by the Bank of Lithuania to undertak.
								</span>
							</Col>
						</Row>
						<FooterLinks/>
					</Container>
				</footer>

				{/* FOOTER END  */}
			</React.Fragment>
		);
	}
}

export default Footer;
