import React, {Component} from "react";
import {AuthContext} from './Auth';
import {Redirect} from "react-router-dom";

class SignOut extends Component{

    static contextType = AuthContext;

    render() {
        this.context.signOut();
        return (
            <Redirect to={{pathname: "/"}}/>
        );
    }
}

export default SignOut;