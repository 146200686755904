import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/wellness-goal-4.png";

class Section10 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">

							<Col lg={6} className="order-lg-12">
								<div className="features-img">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6} className="order-lg-1">
								<div className="pr-lg-6 mr-lg-5">
									<h3 className="line-height-1_6 text-dark mb-4">
										<strong>
											Gamification that inspires you to live
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Your healthics wallet allows you to experience the healing universe of
										gamification. We employ motivating and engaging visuals to enhance therapy and
										treatment adherence for everyone.
									</p>
									<button
										type="button"
										className="btn rounded-pill text-white my-2"
										style={{backgroundColor: "#1F87F6", border: "none"}}>

										Join The Waiting List
									</button>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section10;
