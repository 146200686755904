import React, {Component} from "react";
import {AuthContext} from "../Auth/Auth";

import Steps from "./Steps";

class Dashboard extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {showModal: false};
    }

    render() {
        return (
            <Steps/>
        );
    }
}

export default Dashboard;