import React, { Component } from "react";
import {
	Container,
	Row,
	Col
} from "reactstrap";

//Slider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Client images
import img1 from "../../assets/images/medical-carosel-1.png";
import medicalServiceImg1 from "../../assets/images/medical-service-1.png";
import medicalServiceImg2 from "../../assets/images/medical-service-2.png";
import medicalServiceImg3 from "../../assets/images/medical-service-3.png";
import medicalServiceImg4 from "../../assets/images/medical-service-4.png";
import SectionTitle from "../Common/SectionTitle";

class Section2medical extends Component {
	state = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			960: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
	};

	render() {
		return (
			<React.Fragment>
				{/* <!-- TESTIMONIAL START --> */}

				<section className="section position-relative">
					<Container>
						<SectionTitle
							title="Medical Products &amp; Services"
							description="Our digital wallet empowers you to take control of your health and achieve your goals."
						/>
						<div className="row">
							<OwlCarousel
								className="owl-theme client-images text-center"
								items={2}
								loop={true}
								margin={10}
								nav={false}
								dots={true}
								autoplay={true}
								responsive={this.state.responsive}
								autoplayHoverPause={true}
								autoplayTimeout={2500}
							>
								<div className="item">
									<section
										style={{background: `url(${img1}) center center no-repeat`, backgroundSize: "cover"}}
										className={"p-0 m-0"}
									>
										<Container style={{height: "600px"}}>

											<Row style={{paddingTop: "300px"}}>
												<Col>
													<h1 className="text-white" style={{fontSize:"40px"}}>
														Medical
													</h1>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<button
														type="button"
														className="btn rounded-left btn-white"
														style={{color: "black"}}
													>
														Sign Up For Free
													</button>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<h6 className="text-white px-5" style={{fontSize:"12px"}}>
														The healthcare budget of EU 27 is 1.5 trillion euro. Digital healthcare can reduce it by 50% and make delivery 60% faster
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#c3b4cb`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Manage your health journey
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={medicalServiceImg1} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Get a quick overview of your journey from Diagnostics to Recovery
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#c3b4cb`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Cross-border healthcare
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={medicalServiceImg2} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Borderless wallet that you can rely on in any country
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#c3b4cb`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														e-Plan for your recovery
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={medicalServiceImg3} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Transitioning from e-Prescriptions towards e-Plans
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#c3b4cb`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize:"30px"}}>
														Share your data for rewards
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={medicalServiceImg4} />
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize:"12px"}}>
														Decide how much of your data is shared with vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

							</OwlCarousel>
						</div>
					</Container>
				</section>
				{/* TESTIMONIAL END */}
			</React.Fragment>
		);
	}
}

export default Section2medical;
