import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/wellness-goal-3.png";

class Section9 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="pr-lg-6 mt-4 ml-lg-5">
									<h3 className="line-height-1_6 text-dark">
										<strong>
											Reach your wellness goals faster with e-Plans
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Healthics is a movement that accelerates the global transition from a hospital
										centric approach to personalised and wellbeing centric approach. We help you
										recover faster, better and only at a fraction of today’s costs.
									</p>

									<button
										type="button"
										className="btn rounded-pill text-white my-2"
										style={{backgroundColor: "#1F87F6", border: "none"}}>

										Join The Waiting List
									</button>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section9;
