import React, { Component } from "react";
import {AccountContext} from '../Admin/Account';
import { Container, Row, Col } from "reactstrap";
import {Redirect} from "react-router-dom";
import ReactFlagsSelect, { AT } from 'react-flags-select';

//Import Images
import img1 from "../../assets/images/asset-1.png";

class Section1 extends Component {

	static contextType = AccountContext;
	static countries = {
		"AT": "+43",
		"BE": "+32",
		"BG": "+359",
		"HR": "+385",
		"CY": "+357",
		"CZ": "+420",
		"DK": "+45",
		"EE": "+372",
		"FI": "+358",
		"FR": "+33",
		"DE": "+49",
		"GR": "+30",
		"HU": "+36",
		"IE": "+354",
		"IT": "+353",
		"LV": "+39",
		"LT": "+370",
		"LU": "+352",
		"MT": "+356",
		"NL": "+31",
		"PO": "+48",
		"PT": "+351",
		"RO": "+40",
		"SK": "+421",
		"SI": "+386",
		"ES": "+34",
		"SE": "+43"
	}

	constructor(props) {
		super(props);
		this.state = {
			clickedSignUp: false,
			phoneNumber: "",
			code: "EE"
		};
	}

	onSignUp() {
		this.setState({clickedSignUp: true})
	}

	render() {
		return (
			(this.state.clickedSignUp) ?
				<Redirect to={{pathname: `/sign-up/${Section1.countries[this.state.code]}${this.state.phoneNumber}`}}/> :
				<React.Fragment>
					{/* FEATURES START  */}
					<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5 mt-5">
						<Container>

							<Row className="align-items-center">

								<Col lg={6} className="order-lg-12 d-none d-lg-block" style={{
									background: `url(${img1}) center center no-repeat`,
									backgroundSize: "cover",
									height: "100vh"
								}}/>

								<Col lg={6} className="order-lg-1 mt-5"
									 style={{height: "50vh", justifyContent: "center"}}>
									<div className="pr-lg-6 mr-lg-5 h-100 align-middle">
										<h2 className="text-dark mb-5" style={{justifyContent: "center"}}>
											<strong className={"align-middle"} style={{fontSize: "40px"}}>
												The Better way to manage your health and lifestyle.
											</strong>
										</h2>

										<p className="text-muted f-15 text-justify h-25 align-middle">
											All in one digital health wallet that allows you to purchase on-demand
											products &amp; services from healthcare professionals and businesses.
										</p>

										<Container>
											<Row className="align-items-top h-25 align-middle">
												<Col className="text-center">
													<div className="input-group lg-3">
														<div className="input-group-prepend">
															<ReactFlagsSelect
																countries={["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PO", "PT", "RO", "SK", "SI", "ES", "SE"]}
																customLabels={Section1.countries}
																selected={this.state.code}
																onSelect={(event) => this.setState({code: event})}
															/>
														</div>
														<input className="form-control h-100 m-0 p-3 border-0"
															   type="edit"
															   placeholder="Your number"
															   value={this.state.phoneNumber}
															   style={{backgroundColor: "#e9ecef"}}
															   onChange={(event) =>
																   this.setState({phoneNumber: event.target.value})}
														/>
														<button
															type="button"
															className="btn text-white"
															onClick={this.onSignUp.bind(this)}
															style={{
																backgroundColor: "#1F87F6",
																border: "none",
																borderTopRightRadius: "25rem",
																borderBottomRightRadius: "25rem"
															}}>

															Get Started
														</button>
													</div>
												</Col>
											</Row>
										</Container>

										<p className="text-muted f-15 mt-5 text-justify h-10 align-middle">
											Get a SMS with a code and sign up for free.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</React.Fragment>
		)
	}
}

export default Section1;
