import React, { Component } from "react";
import {
	Container,
	Row,
	Col
} from "reactstrap";

//Slider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Client images
import img1 from "../../assets/images/insurance-carosel-1.png";
import insuranceServiceImg1 from "../../assets/images/insurance-service-1.png";
import insuranceServiceImg2 from "../../assets/images/insurance-service-2.png";
import insuranceServiceImg3 from "../../assets/images/insurance-service-3.png";
import insuranceServiceImg4 from "../../assets/images/insurance-service-4.png";
import SectionTitle from "../Common/SectionTitle";

class Section4insurance extends Component {
	state = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			960: {
				items: 3,
			},
			1200: {
				items: 3,
			},
		},
	};

	render() {
		return (
			<React.Fragment>
				{/* <!-- TESTIMONIAL START --> */}

				<section className="section position-relative" id="client">
					<Container>
						<SectionTitle
							title="Insurance Products &amp; Services"
							description="You receive so much more than life insurance with Healthics. Get a plan that engages, rewards and protects you and your people."
						/>
						<div className="row">
							<OwlCarousel
								className="owl-theme client-images text-center"
								items={2}
								loop={true}
								margin={10}
								nav={false}
								dots={true}
								autoplay={true}
								responsive={this.state.responsive}
								autoplayHoverPause={true}
								autoplayTimeout={2500}
							>
								<div className="item">
									<section
										style={{background: `url(${img1}) center center no-repeat`, backgroundSize: "cover"}}
									>
										<Container style={{height: "600px"}}>

											<Row style={{paddingTop: "300px"}}>
												<Col>
													<h1 className="text-white" style={{fontSize: "40px"}}>
														Insurance
													</h1>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<button
														type="button"
														className="btn rounded-pill btn-white"
														style={{color: "black"}}
													>
														Sign Up For Free
													</button>
												</Col>
											</Row>

											<Row style={{paddingTop: "50px"}}>
												<Col>
													<h6 className="text-white px-5" style={{fontSize: "12px"}}>
														The healthcare budget of EU 27 is 1.5 trillion euro. Digital
														healthcare can reduce it by 50% and make delivery 60% faster
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#f4eee1`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize: "30px"}}>
														Health Insurance
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={insuranceServiceImg1}/>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize: "12px"}}>
														All insurance products & services are licensed by us and
														operated by vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#f4eee1`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize: "30px"}}>
														Life Insurance
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={insuranceServiceImg2}/>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize: "12px"}}>
														All insurance products & services are licensed by us and
														operated by vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#f4eee1`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize: "30px"}}>
														Travel Insurance
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={insuranceServiceImg3}/>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize: "12px"}}>
														All insurance products & services are licensed by us and
														operated by vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

								<div className="item">
									<section
										style={{backgroundColor: `#f4eee1`}}
									>
										<Container style={{height: "600px"}}>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "30%"}}>
												<Col>
													<h1 className="text-black mx-2" style={{fontSize: "30px"}}>
														Other
													</h1>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"} style={{height: "40%"}}>
												<Col className={"col-9"}>
													<img src={insuranceServiceImg4}/>
												</Col>
											</Row>

											<Row className={"d-flex align-items-center justify-content-center"}>
												<Col>
													<h6 className="text-black px-5" style={{fontSize: "12px"}}>
														All insurance products & services are licensed by us and
														operated by vendors
													</h6>
												</Col>
											</Row>
										</Container>
									</section>
								</div>

							</OwlCarousel>
						</div>
					</Container>
				</section>
				{/* TESTIMONIAL END */}
			</React.Fragment>
		);
	}
}

export default Section4insurance;
