import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Images
import img1 from "../../assets/images/wellness-goal.png";

class Section1 extends Component {
	render() {
		return (
			<React.Fragment>
				{/* FEATURES START  */}
				<section className="section bg-light position-relative pt-2 pb-2 pt-lg-5 pb-lg-5" id="features">
					<Container>

						<Row className="align-items-center">
							<Col lg={6}>
								<div className="features-img mt-4">
									<img
										src={img1}
										className="img-fluid mx-auto d-block"
									/>
								</div>
							</Col>

							<Col lg={6}>
								<div className="pr-lg-6 mt-4 ml-lg-5">
									<h3 className="line-height-1_6 text-dark">
										<strong>
											Communication platform built for the future of wellness
										</strong>
									</h3>
									<p className="text-muted f-15 text-justify">
										Receive notifications directly to your phone or wearable. Communication in
										healthcare is key in reaching your results and milestones. Get an overview of
										your nutrition and fitness plans and discuss challenges directly with your
										healthcare professional.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</React.Fragment>
		);
	}
}

export default Section1;
