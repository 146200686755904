import React, {Component, createContext} from "react";
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";

const AuthContext = createContext();

class Auth extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.currentUser = null;
        this.session = null;
    }

    async signUp(username, password) {
        return await new Promise((resolve, reject) => {

            let dataPhoneNumber = {
                Name : 'phone_number',
                Value : username
            };

            let attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);

            UserPool.signUp(username, password, null, [attributePhoneNumber], (err, data)=> {
                if (err) {
                    console.error("Sign in error:", err);
                    reject(err);
                } else {
                    this.currentUser = data.user;
                    console.log(data);
                    resolve(data);
                }
            });

        });
    }

    async resendConfirmationCode() {
        return await new Promise((resolve, reject) => {
            if (this.currentUser) {
                this.currentUser.resendConfirmationCode((err, data) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    async signIn(username, password) {
        return await new Promise((resolve, reject) => {

            const user = new CognitoUser({Username: username, Pool: UserPool});

            const authDetails = new AuthenticationDetails({Username: username, Password: password});

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    this.currentUser = user;
                    resolve(data);
                },
                onFailure: (err) => {
                    console.log("onFailure: ", err);
                    reject(err);
                },
                mfaRequired:(challengeName, challengeParameters) =>{
                    console.log("challengeName: ", challengeName);
                    console.log("challengeParameters: ", challengeParameters);
                    this.currentUser = user;
                    resolve({challengeName, challengeParameters});
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired: ", data);
                    resolve(data);
                }
            });
        });
    };

    async sendMFACode(code) {
        return await new Promise((resolve, reject) => {

            if (this.currentUser) {
                this.currentUser.sendMFACode(code, {
                    onSuccess: (data) => {
                        console.log(data);
                        this.currentUser = data.user;
                        resolve(data);
                    },
                    onFailure: (err) =>{
                        console.log("Error: ", err);
                        reject(err);
                    }
                });
            } else {
                reject(new Error("Session has expired. Please, try again!"));
            }
        });
    };

    async confirmRegistration(code) {
        return await new Promise((resolve, reject) => {
            if (this.currentUser) {
                this.currentUser.confirmRegistration(code, true,(err, data) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    async forgotPassword(phoneNumber) {
        return await new Promise((resolve, reject) => {
            let user = new CognitoUser({
                Username: phoneNumber,
                Pool: UserPool
            });

            if (user) {
                user.forgotPassword({
                    onSuccess: (data) => {
                        console.log(data);
                        resolve(data);
                    },
                    onFailure: (err) =>{
                        console.log("Error: ", err);
                        reject(err);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    async confirmPassword(email, code, password) {
        return await new Promise((resolve, reject) => {

            let user = new CognitoUser({
                Username: email,
                Pool: UserPool
            });

            if (user) {
                user.confirmPassword( code, password, {
                    onSuccess: (data) => {
                        console.log(data);
                        resolve(data);
                    },
                    onFailure: (err) =>{
                        console.log("Error: ", err);
                        reject(err);
                    }
                });
            } else {
                reject(new Error("User does not exist"));
            }
        });
    };

    async getSession(){
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                if (!this.session) {
                    user.getSession((err, new_session) => {
                        if (err) {
                            reject();
                        } else {
                            this.session = new_session;
                            resolve({session: this.session, user: user});
                        }
                    });
                } else {
                    resolve({session: this.session, user: user});
                }
            } else {
                reject();
            }
        });
    };

    async getCurrentUser() {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                resolve(user);
            } else {
                reject();
            }
        });
    };

    signOut(){
        const user = UserPool.getCurrentUser();

        console.log("User", user);

        if (user) {
            user.signOut();
        }
    };

    render() {
        return (
            <AuthContext.Provider value={{
                signUp: this.signUp,
                confirmRegistration: this.confirmRegistration,
                resendConfirmationCode: this.resendConfirmationCode,
                signIn: this.signIn,
                sendMFACode: this.sendMFACode,
                forgotPassword: this.forgotPassword,
                confirmPassword: this.confirmPassword,
                getSession: this.getSession,
                signOut: this.signOut
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export {AuthContext};
export default Auth;