import React, {Component} from "react";
import {AuthContext} from './Auth';
import {Link, Redirect} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class SignIn extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: "",
            redirect: null,
            password: "",
            error: null,
            codeSent: false
        };
    }

    onSubmit(event) {
        event.preventDefault();
        let _this = this;

        this.context.signIn(this.state.phoneNumber, this.state.password)
            .then(data => {
                if (data.challengeName) {
                    this.setState({redirect: "/mfa-verification-code"})
                } else {
                    _this.context.getSession().then((result) => {
                        if (result.session) {
                            _this.setState({
                                redirect: "/dashboard"
                            });
                        } else {
                            _this.setState({
                                redirect: null
                            });
                        }
                    });
                }
            })
            .catch(err => {
                this.setState({error: err.message})
                console.error("Failed to login", err)
            });
    }

    render() {
        return (
            (this.state.redirect) ?
                <Redirect to={{pathname: this.state.redirect}}/>
                :
                <form onSubmit={this.onSubmit.bind(this)}>
                    <Container className={"vh-100"}>
                        <Row className={"justify-content-md-center"}>
                            <Col>
                                {
                                    this.state.error &&
                                    <Alert variant={"danger"}>
                                        {this.state.error.replace("USERNAME", "Email")}
                                    </Alert>
                                }
                            </Col>
                        </Row>
                        <Row className={"d-flex justify-content-center"} style={{height:"80%"}}>
                            <Col lg={"5"} md={"7"} sm={"9"} xs={"11"}
                                 className={"d-flex align-items-center justify-content-center"}>

                                <Container>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} className="mb-3" controlId="formHorizontalEmail">
                                            <Form.Label column>
                                                Phone Number:
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="Phone Number" value={this.state.phoneNumber}
                                                          onChange={(event) => this.setState({phoneNumber: event.target.value})}/>

                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} className="mb-3" controlId="formHorizontalPassword">
                                            <Form.Label column>
                                                Password:
                                            </Form.Label>
                                            <Form.Control type="password" placeholder="Password"
                                                          value={this.state.password}
                                                          onChange={(event) => this.setState({password: event.target.value})}/>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                            <Button variant="primary" type="submit" className={"w-75"}>
                                                Sign In
                                            </Button>
                                        </Col>
                                        <Col className={"d-flex align-items-center justify-content-center"} xs={6}>
                                            <Link to="/sign-up">Sign Up</Link>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className={"d-flex align-items-center justify-content-center"}>
                                            <Link to="/forgot">Forgot Password</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </form>
        );
    }
}

export default SignIn;